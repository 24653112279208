import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ADMIN_ROLE } from 'src/app/shared/const';
import { jwtDecode } from 'jwt-decode';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalAuthFilter implements CanActivate, CanActivateChild {
  constructor(private authSvc: AuthService) {}

  canActivateChild(): Observable<boolean> {
    return this.can();
  }

  canActivate(): Observable<boolean> {
    return this.can();
  }

  private can(): Observable<boolean> {
    if (!this.authSvc.hasValidToken()) {
      return of(false);
    }
    const payload = jwtDecode(this.authSvc.accessToken);
    const payloadAsAny = payload as any;
    const roles = typeof payloadAsAny.role === 'string' ? [payloadAsAny.role] : payloadAsAny.role;

    if (!roles?.includes(ADMIN_ROLE)) {
      return of(false);
    }
    return of(true);
  }
}
