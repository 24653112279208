import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Renderer2 } from '@angular/core';
import { NavigationItem } from 'diflexmo-angular-design';
import { Subject, takeUntil } from 'rxjs';
import { DestroyableComponent } from 'src/app/shared/components/destroyable.component';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'dfm-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrls: [],
})
export class BaseLayoutComponent extends DestroyableComponent implements AfterViewInit {
  private anchorTag!: any;

  public navigationItems: NavigationItem[] = [
    new NavigationItem('Back to fordesk', 'home-03', './', true),
    new NavigationItem('Templates', 'home-03', '/templates', false),
  ];

  public isLoaderActive$$ = new Subject<boolean>();

  constructor(public loaderService: LoaderService, private cdr: ChangeDetectorRef, private renderer: Renderer2, private el: ElementRef) {
    super();
  }

  public ngAfterViewInit(): void {
    this.loaderService.isActive$.pipe(takeUntil(this.destroy$$)).subscribe({
      next: (value) => {
        this.isLoaderActive$$.next(value);
        this.cdr.detectChanges();
      },
    });
    this.anchorTag = this.el.nativeElement.getElementsByTagName('a');
    if (this.anchorTag?.length) {
      this.renderer.listen(this.anchorTag?.[0], 'click', (event) => {
        event.stopPropagation();
        event.stopImmediatePropagation();
        window.location.href = 'https://staging.fordesk.net';
      });
    }
    if (this.anchorTag?.length > 2) {
      this.renderer.listen(this.anchorTag?.[2], 'click', (event) => {
        event.stopPropagation();
        event.stopImmediatePropagation();
        window.location.href = 'https://staging.fordesk.net';
      });
    }
  }
}
