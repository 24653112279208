import { AuthConfig } from 'angular-oauth2-oidc';

const authentication: AuthConfig = {
  issuer: '#{TemplateManagementUI.Authentication.Issuer}#',
  redirectUri: window.location.origin,
  clientId: 'fordesk_templates_mgt',
  scope: 'openid profile fordesk_templates_mgt_api identity_api',
  postLogoutRedirectUri: `${window.location.origin}/login`,
  loginUrl: '#{TemplateManagementUI.Authentication.LoginUrl}#',
  requireHttps: false,
  silentRefreshRedirectUri: `${window.location.origin}/assets/silent-refresh.html`,
  silentRefreshShowIFrame: false,
};

export const environment = {
  authentication,
  production: true,
  syncFusionLicenseKey: '#{SyncfusionAspNetCoreLicenseKey}#',
  apiHostUrl: '#{TemplateManagementUI.Api}#',
  identityApiConfig: {
    host: '#{TemplateManagementUI.Authentication.IdentityApi}#',
  },
};
