import { NgModule } from '@angular/core';
import { DesignSystemCoreModule, DesignSystemModule } from 'diflexmo-angular-design';
import { CommonModule } from '@angular/common';
import { BaseLayoutComponent } from './components/base-layout/base-layout.component';
import { AppRoutingModule } from '../app-routing.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [BaseLayoutComponent],
  imports: [CommonModule, DesignSystemCoreModule, AppRoutingModule, DesignSystemModule, SharedModule],
  exports: [AppRoutingModule],
})
export class CoreModule {}
