import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DesignSystemModule, TableModule } from 'diflexmo-angular-design';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DestroyableComponent } from './components/destroyable.component';

@NgModule({
  declarations: [DestroyableComponent],
  imports: [CommonModule, DesignSystemModule, ReactiveFormsModule, TableModule, MatProgressBarModule],
  exports: [DesignSystemModule, TableModule, MatProgressBarModule],
  providers: [],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
    };
  }
}
