// import { HTTP_INTERCEPTORS } from `@angular/common/http`;
import { APP_INITIALIZER, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { AuthConfig, OAuthModule, OAuthModuleConfig, OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { authModuleConfig } from './config/auth-module-config';
import { AuthRedirectInterceptor } from './interceptors/auth.interceptor';
import { AuthService } from './services/auth.service';
import { GlobalAuthFilter } from './guards/global-auth-filter.guard';

export function initApplication(oauthService: OAuthService) {
  return () => {
    oauthService.configure(environment.authentication);
    // eslint-disable-next-line no-param-reassign
    oauthService.tokenValidationHandler = new JwksValidationHandler();
    return oauthService.loadDiscoveryDocumentAndTryLogin().then(() => {
      if (oauthService.hasValidAccessToken()) {
        oauthService.setupAutomaticSilentRefresh();
      } else {
        oauthService.initImplicitFlow();
      }
    });
  };
}

@NgModule({
  imports: [OAuthModule.forRoot(authModuleConfig)],
  providers: [AuthService, GlobalAuthFilter],
})
export class AuthModule {
  public static forRoot(): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        { provide: AuthConfig, useValue: environment.authentication },
        { provide: APP_INITIALIZER, useFactory: initApplication, deps: [OAuthService, AuthService], multi: true },
        { provide: OAuthModuleConfig, useValue: authModuleConfig },
        { provide: OAuthStorage, useValue: localStorage },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthRedirectInterceptor,
          multi: true,
        },
      ],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: AuthModule) {
    if (parentModule) {
      throw new Error(`CoreModule is already loaded. Import it in the AppModule only`);
    }
  }
}
