import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BaseLayoutComponent } from './core/components/base-layout/base-layout.component';
import { GlobalAuthFilter } from './modules/auth/guards/global-auth-filter.guard';

const routes: Routes = [
  {
    path: '',
    component: BaseLayoutComponent,
    canActivate: [GlobalAuthFilter],
    children: [
      {
        title: 'Fordesk | Templates',
        path: 'templates',
        loadChildren: async () => (await import('./modules/mail-merge-templates/mail-merge-templates.module')).MailMergeTemplatesModule,
      },
      {
        path: '',
        redirectTo: '/templates',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
