import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { registerLicense } from '@syncfusion/ej2-base';
import { OAuthErrorEvent } from 'angular-oauth2-oidc';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

// Registering SyncFusion license key
registerLicense(environment.syncFusionLicenseKey);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => {
    if (err instanceof OAuthErrorEvent && err.type === 'invalid_nonce_in_state') {
      window.location.href = environment.authentication.redirectUri;
    } else {
      console.log(err);
    }
  });
